import React from 'react';
import A from '../components/externalLink';
import SectionTemplate from '../components/sectionTemplate';

const SECTIONS = [
  {
    header: 'Parameters',
    parameters: [
      {
        name: 'years',
        details: 'required',
        info: 'A comma-delimited list of four digit years. Set to "all" to include all years.',
      },
      {
        name: 'teamIDs',
        details: 'optional',
        info: 'A comma-delimited list of team IDs',
      },
      {
        name: 'playerIDs',
        details: 'optional',
        info: 'A comma-delimited list of player IDs',
      },
    ],
  },
  {
    header: 'Returns',
    parameters: [
      {
        name: 'playerID',
        details: 'string',
        info: 'The player\'s ID',
      },
      {
        name: 'firstName',
        details: 'string',
        info: 'The player\'s first name',
      },
      {
        name: 'lastName',
        details: 'string',
        info: 'The player\'s last name',
      },
      {
        name: 'teams',
        details: 'array of team objects',
        info: 'The teams that have rostered the player',
      },
      {
        name: 'teams.teamID',
        details: 'string',
        info: 'The team\'s ID',
      },
      {
        name: 'teams.active',
        details: 'boolean',
        info: 'Whether the player is actively on the team. Non-active players either changed teams or are practice players.',
      },
      {
        name: 'teams.year',
        details: 'integer',
        info: 'The year that the player was rostered on the team',
      },
      {
        name: 'teams.jerseyNumber',
        details: 'string',
        info: 'The player\'s number on the team. This is a string to allow both "0" and "00"',
      },
    ],
  },
];

export default ({ id }) => {
  return (
    <div className="section" id={id}>
      <hr />
      <h2 className="section-header">Players</h2>
      <div className="section-container">
        <div className="section-left">
          <div className="description">
            Returns a list of players.
            This API contains a player's first name, last name, and teams.
            The playerID is a unique identifier to call other API endpoints and link to the website (e.g. <A href="https://watchufa.com/league/players/aweaver">watchufa.com/league/player/aweaver</A>).
          </div>
          <hr />
          <SectionTemplate sections={SECTIONS} />
        </div>
        <div className="section-right">
          <div className="sticky">
            <div className="code-example">
              <div className="code-example-header">
                ENDPOINT
              </div>
              <div className="code-example-body">
                <A href={process.env.REACT_APP_EXPRESS_URL + 'players?years=2019'}>
                  <code>{process.env.REACT_APP_EXPRESS_URL + 'players'}</code>
                </A>
              </div>
            </div>
            <div className="response-example">
              <div className="response-example-header">
                RESPONSE
              </div>
              <pre className="response-example-body">{
                `{
  "object": `}<span className="json-string">"list"</span>{`,
  "data": [
    {
      "playerID": `}<span className="json-string">"aweaver"</span>{`,
      "firstName": `}<span className="json-string">"Aaron"</span>{`,
      "lastName": `}<span className="json-string">"Weaver"</span>{`,
      "teams": [
        {
          "teamID": `}<span className="json-string">"aviators"</span>{`
          "active": `}<span className="json-bool">true</span>{`,
          "year": `}<span className="json-number">2019</span>{`,
          "jerseyNumber": `}<span className="json-string">"18"</span>{`,
        }
      ]
    },
    {...},
    {...}
  ]
}`}
              </pre>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};
