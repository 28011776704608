import { createRoot } from 'react-dom/client';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import './index.css';
import Main from './routes/Main';
import EventList from './routes/EventList';

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/events" element={<EventList />} />
        <Route path="/" element={<Main />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
};

createRoot(document.getElementById('root')).render(<App />);
