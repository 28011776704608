import React, { useRef, useLayoutEffect, useState } from 'react';
import { Link, Element } from 'react-scroll';
import eventTypesRaw from '../constants/eventTypes';

export default () => {
  const eventTypes = eventTypesRaw.map(({ id, title, elements }) => {
    return {
      id,
      label: id + ') ' + title,
      elements,
      ref: useRef(),
    };
  });
  const [activeEventType, setActiveEventType] = useState(eventTypes[0].id);

  useLayoutEffect(() => {
    const handleScroll = (event) => {
      const { scrollTop } = event.target.scrollingElement;
      let mostRecent = 0;
      for (const eventType of eventTypes) {
        if (eventType.ref.current.offsetTop + eventType.ref.current.parentNode.offsetTop <= scrollTop) {
          mostRecent = eventType.id;
        } else { break; }
      }
      if (mostRecent !== activeEventType) {
        setActiveEventType(mostRecent);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  });

  const clickNav = (id) => {
    setActiveEventType(id);
  };

  return (
    <>
      <div className="nav">
        <div className="nav-container">
          <div className="nav-header">UFA API</div>
          <div className={'nav-list'}>
            {eventTypes.map(({ id, label }) =>
              <Link className={'nav-item' + (id === activeEventType ? ' active' : '')} key={id} to={id} offset={5} onClick={() => clickNav(id)}>{label}</Link>,
            )}
          </div>
        </div>
      </div>
      <Element name="container" className="body" >
        <div className="section">
          <h1 className="section-header">Game Events</h1>
          <div className="section-container">
            <div className="section">
              Each item in the game event arrays has a type and supporting fields. This page has every possible type and the list of supporting fields. Some types don't have any supporting fields.
            </div>
          </div>
        </div>
        <hr />
        {eventTypes.map(({ id, label, elements, ref }, typeIndex) =>
          <Element className="section">
            <span id={id} name={id} className="anchor" ref={ref} />
            {typeIndex !== 0 ? <hr /> : null}
            <div className="section" key={id}>
              <h3>{label}</h3>
              {Array.isArray(elements) ? elements.map((element, elementIndex) =>
                <>
                  {elementIndex !== 0 ? <div className="divider" /> : null}
                  <div className="description" key={element.id}>
                    <div className="label">
                      <div className="label-name">{element.id}</div>
                      <div className="label-details">{element.type}</div>
                    </div>
                    <div className="info">{element.description}</div>
                  </div>
                </>,
              ) : null}
            </div>
          </Element>,
        )}
      </Element >
    </>
  );
};
