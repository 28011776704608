import React from 'react';

export default ({ sections }) => {
  return <>
    {sections.map(({ header, parameters }, sectionIndex) =>
      <React.Fragment key={sectionIndex} >
        {sectionIndex !== 0 ? <hr /> : null}
        <h3>{header}</h3>
        {parameters.map(({ name, details, info, examples }, parameterIndex) =>
          <React.Fragment key={parameterIndex} >
            {parameterIndex !== 0 ? <div className="divider" /> : null}
            <div className="description">
              <div className="label">
                <div className="label-name">{name}</div>
                <div className="label-details">{details}</div>
              </div>
              <div className="info">
                {info}
                {Array.isArray(examples) ?
                  <ul>
                    {examples.map((Example, exampleIndex) => <li key={exampleIndex}><Example /></li>)}
                  </ul> :
                  null}
              </div>
            </div>
          </React.Fragment>,
        )}
      </React.Fragment>,
    )}
  </>;
};
