import React from 'react';
import A from '../components/externalLink';
import SectionTemplate from '../components/sectionTemplate';

const SECTIONS = [
  {
    header: 'Parameters',
    parameters: [
      {
        name: 'gameID',
        details: 'required',
        info: 'The game ID to query',
      },
    ],
  },
  {
    header: 'Returns',
    parameters: [
      {
        name: 'homeEvents',
        details: 'array',
        info: 'The events as recorded by the home team.',
      },
      {
        name: 'awayEvents',
        details: 'array',
        info: 'The events as recorded by the away team.',
      },
    ],
  },
];

export default () => {
  return (
    <div className="section">
      <hr />
      <h2 className="section-header">Game Events</h2>
      <div className="section-container">
        <div className="section-left">
          <div className="description">
            Returns the events of a game as recorded by the home and away team. Each event has a type field that determines the other fields present for the event. You can find the full list of types <a href="/events">here</a>.
          </div>
          <hr />
          <SectionTemplate sections={SECTIONS} />
        </div>
        <div className="section-right">
          <div className="sticky">
            <div className="code-example">
              <div className="code-example-header">
                ENDPOINT
              </div>
              <div className="code-example-body">
                <A href={process.env.REACT_APP_EXPRESS_URL + 'gameEvents?gameID=2019-08-11-DAL-NY'}>
                  <code>{process.env.REACT_APP_EXPRESS_URL + 'gameEvents'}</code>
                </A>
              </div>
            </div>
            <div className="response-example">
              <div className="response-example-header">
                RESPONSE
              </div>
              <pre className="response-example-body">{
                `{
  "object": `}<span className="json-string">"object"</span>{`,
  "data": {
    "homeEvents": [
      {
        "type": `}<span className="json-number">1</span>{`,
        "line": [
          `}<span className="json-string">"jalorro"</span>{`,
          `}<span className="json-string">"mlemar"</span>{`,
          `}<span className="json-string">"mdrost"</span>{`,
          `}<span className="json-string">"bkittredg"</span>{`,
          `}<span className="json-string">"jmieser"</span>{`,
          `}<span className="json-string">"jalorro"</span>{`,
          `}<span className="json-string">"mbrownlee"</span>{`
        ],
        "time": `}<span className="json-number">0</span>{`
      },
      {...}
    ],
    "awayEvents": [
      {...},
      {...}
    ]
  ]
}`}
              </pre>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};
