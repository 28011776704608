module.exports = [
  {
    id: 1,
    title: 'Start D Point',
    elements: [
      {
        id: 'line',
        description: 'Array of playerIDs that started on the defensive point.',
        type: 'string array',
      },
      {
        id: 'time',
        description: 'Time in seconds into the period when the defensive point started.',
        type: 'integer',
      },
    ],
  },
  {
    id: 2,
    title: 'Start O Point',
    elements: [
      {
        id: 'line',
        description: 'Array of playerIDs that started on the offensive point.',
        type: 'string array',
      },
      {
        id: 'time',
        description: 'Time in seconds into the period when the offensive point started.',
        type: 'integer',
      },
    ],
  },
  {
    id: 3,
    title: 'Midpoint Timeout - recording team',
    elements: [
      {
        id: 'line',
        description: 'Array of playerIDs that came onto the field during the timeout.',
        type: 'string array',
      },
    ],
  },
  {
    id: 4,
    title: 'Between Point Timeout - recording team',
  },
  {
    id: 5,
    title: 'Midpoint Timeout - opposing team',
    elements: [
      {
        id: 'line',
        description: 'Array of playerIDs that came onto the field during the timeout.',
        type: 'string array',
      },
    ],
  },
  {
    id: 6,
    title: 'Between Point Timeout - opposing team',
  },
  {
    id: 7,
    title: 'Pull - inbounds',
    elements: [
      {
        id: 'puller',
        description: 'playerID pulling the disc to start the point.',
        type: 'string',
      },
      {
        id: 'pullX',
        description: 'The X coordinates where the pull was brought into play.',
        type: 'double',
      },
      {
        id: 'pullY',
        description: 'The Y coordinates where the pull was brought into play.',
        type: 'double',
      },
      {
        id: 'pullMs',
        description: 'The milliseconds between when the pull was thrown and when the pull was brought into play.',
        type: 'integer',
      },
    ],
  },
  {
    id: 8,
    title: 'Pull - out of bounds',
    elements: [
      {
        id: 'puller',
        description: 'playerID pulling the disc to start the point.',
        type: 'string',
      },
    ],
  },
  {
    id: 9,
    title: 'Offsides - recording team',
    elements: [
      {
        id: 'puller',
        description: 'playerID pulling the disc to start the point.',
        type: 'string',
      },
    ],
  },
  {
    id: 10,
    title: 'Offsides - opposing team',
    elements: [
      {
        id: 'puller',
        description: 'playerID pulling the disc to start the point.',
        type: 'string',
      },
    ],
  },
  {
    id: 11,
    title: 'Block',
    elements: [
      {
        id: 'defender',
        description: 'playerID getting the block.',
        type: 'string',
      },
    ],
  },
  {
    id: 12,
    title: 'Callahan - thrown by opposing team',
    elements: [
      {
        id: 'defender',
        description: 'playerID catching the callahan.',
        type: 'string',
      },
    ],
  },
  {
    id: 13,
    title: 'Throwaway - thrown by opposing team',
  },
  {
    id: 14,
    title: 'Stall - against opposing team',
  },
  {
    id: 15,
    title: 'Score - by opposing team',
  },
  {
    id: 16,
    title: 'Penalty - on recording team',
  },
  {
    id: 17,
    title: 'Penalty - on opposing team',
  },
  {
    id: 18,
    title: 'Pass',
    elements: [
      {
        id: 'thrower',
        description: 'playerID throwing the disc.',
        type: 'string',
      },
      {
        id: 'throwerX',
        description: 'The X coordinates where the disc was thrown from.',
        type: 'double',
      },
      {
        id: 'throwerY',
        description: 'The Y coordinates where the disc was thrown from.',
        type: 'double',
      },
      {
        id: 'receiver',
        description: 'playerID receiving the disc.',
        type: 'string',
      },
      {
        id: 'receiverX',
        description: 'The X coordinates where the receiver was.',
        type: 'double',
      },
      {
        id: 'receiverY',
        description: 'The Y coordinates where the receiver was.',
        type: 'double',
      },
    ],
  },
  {
    id: 19,
    title: 'Goal',
    elements: [
      {
        id: 'thrower',
        description: 'playerID throwing the disc.',
        type: 'string',
      },
      {
        id: 'throwerX',
        description: 'The X coordinates where the disc was thrown from.',
        type: 'double',
      },
      {
        id: 'throwerY',
        description: 'The Y coordinates where the disc was thrown from.',
        type: 'double',
      },
      {
        id: 'receiver',
        description: 'playerID receiving the disc.',
        type: 'string',
      },
      {
        id: 'receiverX',
        description: 'The X coordinates where the receiver was.',
        type: 'double',
      },
      {
        id: 'receiverY',
        description: 'The Y coordinates where the receiver was.',
        type: 'double',
      },
    ],
  },
  {
    id: 20,
    title: 'Drop',
    elements: [
      {
        id: 'thrower',
        description: 'playerID throwing the disc.',
        type: 'string',
      },
      {
        id: 'throwerX',
        description: 'The X coordinates where the disc was thrown from.',
        type: 'double',
      },
      {
        id: 'throwerY',
        description: 'The Y coordinates where the disc was thrown from.',
        type: 'double',
      },
      {
        id: 'receiver',
        description: 'playerID dropping the disc.',
        type: 'string',
      },
      {
        id: 'receiverX',
        description: 'The X coordinates where the receiver was when the disc was dropped.',
        type: 'double',
      },
      {
        id: 'receiverY',
        description: 'The Y coordinates where the receiver was when the disc was dropped.',
        type: 'double',
      },
    ],
  },
  {
    id: 21,
    title: 'Dropped Pull',
    elements: [
      {
        id: 'receiver',
        description: 'playerID receiving the disc.',
        type: 'string',
      },
      {
        id: 'receiverX',
        description: 'The X coordinates where the receiver was when the pull was dropped.',
        type: 'double',
      },
      {
        id: 'receiverY',
        description: 'The Y coordinates where the receiver was when the pull was dropped.',
        type: 'double',
      },
    ],
  },
  {
    id: 22,
    title: 'Throwaway - thrown by recording team',
    elements: [
      {
        id: 'thrower',
        description: 'playerID throwing the disc.',
        type: 'string',
      },
      {
        id: 'throwerX',
        description: 'The X coordinates where the disc was thrown from.',
        type: 'double',
      },
      {
        id: 'throwerY',
        description: 'The Y coordinates where the disc was thrown from.',
        type: 'double',
      },
      {
        id: 'turnoverX',
        description: 'The X coordinates where the turnover landed.',
        type: 'double',
      },
      {
        id: 'turnoverY',
        description: 'The Y coordinates where the turnover landed.',
        type: 'double',
      },
    ],
  },
  {
    id: 23,
    title: 'Callahan - thrown by recording team',
    elements: [
      {
        id: 'thrower',
        description: 'playerID throwing the disc.',
        type: 'string',
      },
      {
        id: 'throwerX',
        description: 'The X coordinates where the disc was thrown from.',
        type: 'double',
      },
      {
        id: 'throwerY',
        description: 'The Y coordinates where the disc was thrown from.',
        type: 'double',
      },
      {
        id: 'turnoverX',
        description: 'The X coordinates where the turnover was intercepted.',
        type: 'double',
      },
      {
        id: 'turnoverY',
        description: 'The Y coordinates where the turnover was intercepted.',
        type: 'double',
      },
    ],
  },
  {
    id: 24,
    title: 'Stall - against recording team',
    elements: [
      {
        id: 'thrower',
        description: 'playerID holding the disc longer than 7 seconds.',
        type: 'string',
      },
      {
        id: 'throwerX',
        description: 'The X coordinates where the stall occurred.',
        type: 'double',
      },
      {
        id: 'throwerY',
        description: 'The Y coordinates where the stall occurred.',
        type: 'double',
      },
    ],
  },
  {
    id: 25,
    title: 'Injury',
    elements: [
      {
        id: 'line',
        description: 'Array of playerIDs after all injury substitutions take place.',
        type: 'string array',
      },
    ],
  },
  {
    id: 26,
    title: 'Player Misconduct Foul',
    elements: [
      {

        id: 'player',
        description: 'playerID receiving the misconduct foul.',
        type: 'string',
      },
    ],
  },
  {
    id: 27,
    title: 'Player Ejected',
    elements: [
      {
        id: 'player',
        description: 'playerID ejected from the game.',
        type: 'string',
      },
    ],
  },
  {
    id: 28,
    title: 'End of First Quarter',
  },
  {
    id: 29,
    title: 'Halftime',
  },
  {
    id: 30,
    title: 'End of Third Quarter',
  },
  {
    id: 31,
    title: 'End of Regulation',
  },
  {
    id: 32,
    title: 'End of First Overtime',
  },
  {
    id: 33,
    title: 'End of Second Overtime',
  },
  {
    id: 34,
    title: 'Delayed',
  },
  {
    id: 35,
    title: 'Postponed',
  },
];
