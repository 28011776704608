import React, { useRef, useLayoutEffect, useState } from 'react';
import { Link, Element } from 'react-scroll';
import introduction from '../sections/introduction';
import teams from '../sections/teams';
import players from '../sections/players';
import games from '../sections/games';
import playerGameStats from '../sections/playerGameStats';
import gameEvents from '../sections/gameEvents';
import playerStats from '../sections/playerStats';

export default () => {
  const [activeRoute, setActiveRoute] = useState('introduction');
  const [navOpen, setNavOpen] = useState(false);
  const routes = [
    { id: 'introduction', label: 'Introduction', Component: introduction, ref: useRef() },
    { id: 'teams', label: 'Teams', Component: teams, ref: useRef() },
    { id: 'players', label: 'Players', Component: players, ref: useRef() },
    { id: 'games', label: 'Games', Component: games, ref: useRef() },
    { id: 'game-events', label: 'Game Events', Component: gameEvents, ref: useRef() },
    { id: 'player-game-stats', label: 'Player Game Stats', Component: playerGameStats, ref: useRef() },
    { id: 'player-stats', label: 'Player Stats', Component: playerStats, ref: useRef() },
  ];

  useLayoutEffect(() => {
    const handleScroll = (event) => {
      const { scrollTop } = event.target.scrollingElement;
      let mostRecent = 'introduction';
      for (const route of routes) {
        if (route.ref.current.offsetTop + route.ref.current.parentNode.offsetTop <= scrollTop) {
          mostRecent = route.id;
        } else { break; }
      }
      if (mostRecent !== activeRoute) {
        setActiveRoute(mostRecent);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  });

  const clickNav = (id) => {
    setNavOpen(false);
    setActiveRoute(id);
  };
  return (
    <>
      <div className="nav">
        <div className="nav-container">
          <div className="nav-header">UFA API</div>
          <div className="nav-dropdown" onClick={() => setNavOpen(!navOpen)}>
            <div className="nav-dropdown-text">Navigation</div>
            <div className={'nav-dropdown-arrow' + (navOpen ? ' open' : '')}>❯</div>
          </div>
        </div>
        <div className={'nav-list' + (navOpen ? ' open' : '')}>
          {routes.map(({ id, label }) =>
            <Link className={'nav-item' + (id === activeRoute ? ' active' : '')} key={id} to={id} offset={5} onClick={() => clickNav(id)}>{label}</Link>,
          )}
        </div>
      </div>
      <Element name="container" className="body" >
        {routes.map(({ id, Component, ref }) =>
          <Element className="section" key={id}>
            <span id={id} name={id} className="anchor" ref={ref} />
            <Component />
          </Element >,
        )}
      </Element >
    </>
  );
};
