import React from 'react';

export default ({ id }) => {
  return (
    <div className="section" id={id}>
      <h1 className="section-header">UFA API Reference</h1>
      <div className="section-container">
        <div className="section-left">
          The UFA API retrieves statistical data from the league. Every API call uses the GET request and returns an array of data. At this time, we do not have any authentication or API keys. That may change in the future as interest in UFA grows. All API calls return JSON-encoded responses and use standard HTTP response codes.
        </div>
        <div className="section-right">
          <div className="code-example">
            <div className="code-example-header">
              BASE URL
            </div>
            <pre className="code-example-body">
              <code>{process.env.REACT_APP_EXPRESS_URL}</code>
            </pre>
          </div>
        </div>
      </div>
    </div>
  );
};
