import React from 'react';
import A from '../components/externalLink';
import SectionTemplate from '../components/sectionTemplate';

const SECTIONS = [
  {
    header: 'Parameters',
    parameters: [
      {
        name: 'years',
        details: 'required',
        info: 'A comma-delimited list of four digit years. Set to "all" to include all years.',
      },
      {
        name: 'teamIDs',
        details: 'optional',
        info: 'A comma-delimited list of team IDs',
      },
      {
        name: 'divisionIDs',
        details: 'optional',
        info: 'A comma-delimited list of division IDs',
      },

    ],
  },
  {
    header: 'Returns',
    parameters: [
      {
        name: 'teamID',
        details: 'string',
        info: 'The team\'s ID',
      },
      {
        name: 'year',
        details: 'integer',
        info: 'The four digit year that this team played in',
      },
      {
        name: 'division.divisionID',
        details: 'string',
        info: 'The ID of the team\'s division',
      },
      {
        name: 'division.name',
        details: 'string',
        info: 'The name of the team\'s division',
      },
      {
        name: 'city',
        details: 'string',
        info: 'The team\'s current city',
      },
      {
        name: 'name',
        details: 'string',
        info: 'The team\'s name',
      },
      {
        name: 'fullName',
        details: 'string',
        info: 'The team\'s full name used when space is available',
      },
      {
        name: 'abbrev',
        details: 'string',
        info: 'The team\'s two or three letter abbrevation',
      },
      {
        name: 'wins',
        details: 'integer',
        info: 'The team\'s number of wins',
      },
      {
        name: 'losses',
        details: 'integer',
        info: 'The team\'s number of losses',
      },
      {
        name: 'ties',
        details: 'integer',
        info: 'The team\'s number of ties',
      },
      {
        name: 'standing',
        details: 'integer',
        info: 'The team\'s current standing',
      },
    ],
  },
];

export default ({ id }) => {
  return (
    <div className="section" id={id}>
      <hr />
      <h2 className="section-header">Teams</h2>
      <div className="section-container">
        <div className="section-left">
          <div className="description">
            Returns a list of teams.
            This API contains a team's division, standing, win/loss record, and full team name.
            The teamID is a consistent identifier to call other API endpoints and link to the website (e.g. <A href="https://watchufa.com/spiders">watchufa.com/spiders</A>).
            The teamID will stay consistent even if a team changes its city.
          </div>
          <hr />
          <SectionTemplate sections={SECTIONS} />
        </div>
        <div className="section-right">
          <div className="sticky">
            <div className="code-example">
              <div className="code-example-header">
                ENDPOINT
              </div>
              <div className="code-example-body">
                <A href={process.env.REACT_APP_EXPRESS_URL + 'teams?years=2019'}>
                  <code>{process.env.REACT_APP_EXPRESS_URL + 'teams'}</code>
                </A>
              </div>
            </div>
            <div className="response-example">
              <div className="response-example-header">
                RESPONSE
              </div>
              <pre className="response-example-body">{
                `{
  "object": `}<span className="json-string">"list"</span>{`,
  "data": [
    {
      "teamID": `}<span className="json-string">"spiders"</span>{`,
      "year": `}<span className="json-number">2019</span>{`,
      "division": {
        "divisionID": `}<span className="json-string">"west"</span>{`,
        "name": `}<span className="json-string">"West"</span>{`
      },
      "city": `}<span className="json-string">"San Jose"</span>{`,
      "name": `}<span className="json-string">"Spiders"</span>{`,
      "fullName": `}<span className="json-string">"San Jose Spiders"</span>{`,
      "abbrev": `}<span className="json-string">"SJ"</span>{`,
      "wins": `}<span className="json-number">3</span>{`,
      "losses": `}<span className="json-number">9</span>{`,
      "ties": `}<span className="json-number">0</span>{`,
      "standing": `}<span className="json-number">3</span>{`
    },
    {...},
    {...}
  ]
}`}
              </pre>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};
