import React from 'react';
import A from '../components/externalLink';
import SectionTemplate from '../components/sectionTemplate';

const SECTIONS = [
  {
    header: 'Parameters',
    parameters: [
      {
        name: 'playerIDs',
        details: 'required',
        info: 'The player IDs to query. There is a limit of 100 players in a single request.',
      },
      {
        name: 'years',
        details: 'optional',
        info: 'The years to retrieve. If excluded, all years are returned.',
      },
    ],
  },
  {
    header: 'Returns',
    parameters: [
      {
        name: 'player.playerID',
        details: 'string',
        info: 'The player\'s ID',
      },
      {
        name: 'player.firstName',
        details: 'string',
        info: 'The player\'s first name',
      },
      {
        name: 'player.lastName',
        details: 'string',
        info: 'The player\'s last name',
      },
      {
        name: 'year',
        details: 'year',
        info: 'The year the stats were recorded',
      },
      {
        name: 'assists',
        details: 'integer',
        info: 'The number of assists in the year by the player',
      },
      {
        name: 'goals',
        details: 'integer',
        info: 'The number of goals in the year by the player',
      },
      {
        name: 'hockeyAssists',
        details: 'integer',
        info: 'The number of hockey assists in the year by the player',
      },
      {
        name: 'completions',
        details: 'integer',
        info: 'The number of completed throws in the year by the player',
      },
      {
        name: 'throwAttempts',
        details: 'integer',
        info: 'The number of attempted throws in the year by the player',
      },
      {
        name: 'throwaways',
        details: 'integer',
        info: 'The number of throwaways in the year by the player',
      },
      {
        name: 'stalls',
        details: 'integer',
        info: 'The number of times the player was stalled in the year',
      },
      {
        name: 'callahansThrown',
        details: 'integer',
        info: 'The number of callahans thrown in the year by the player. For calculating fantasy, its important to note that the player also gets a throwaway for each callahan thrown',
      },
      {
        name: 'yardsReceived',
        details: 'integer',
        info: 'The number of receiver yards gained during the year by the player. This is purely in the vertical direction. Horizontal yards are not included.',
      },
      {
        name: 'yardsThrown',
        details: 'integer',
        info: 'The number of yards thrown during the year by the player. This is purely in the vertical direction. Horizontal yards are not included.',
      },
      {
        name: 'hucksAttempted',
        details: 'integer',
        info: 'The number of hucks attempted in the year by the player. A huck is any throw more than 40 yards vertically down the field.',
      },
      {
        name: 'hucksCompleted',
        details: 'integer',
        info: 'The number of hucks completed in the year by the player. A huck is any throw more than 40 yards vertically down the field.',
      },
      {
        name: 'catches',
        details: 'integer',
        info: 'The number of catches in the year by the player',
      },
      {
        name: 'drops',
        details: 'integer',
        info: 'The number of drops in the year by the player',
      },
      {
        name: 'blocks',
        details: 'integer',
        info: 'The number of blocks in the year by the player',
      },
      {
        name: 'callahans',
        details: 'integer',
        info: 'The number of callahans caught in the year by the player. For calculating fantasy, its important to note that the player also gets a goal and block for each callahan.',
      },
      {
        name: 'pulls',
        details: 'integer',
        info: 'The number of pulls thrown in the year by the player',
      },
      {
        name: 'obPulls',
        details: 'integer',
        info: 'The number of out of bounds pulls thrown in the year by the player',
      },
      {
        name: 'recordedPulls',
        details: 'integer',
        info: 'The number of pulls thrown in the year by the player where hang time was recorded',
      },
      {
        name: 'recordedPullsHangtime',
        details: 'integer',
        info: 'The total pull hangtime in milliseconds for the player. Divide by recordedPulls to get average hangtime.',
      },
      {
        name: 'oPointsPlayed',
        details: 'integer',
        info: 'The number of offensive points played during the year by the player',
      },
      {
        name: 'oPointsScored',
        details: 'integer',
        info: 'The number of offensive points scored during the year while the player was on the line',
      },
      {
        name: 'dPointsPlayed',
        details: 'integer',
        info: 'The number of defensive points played during the year by the player',
      },
      {
        name: 'dPointsScored',
        details: 'integer',
        info: 'The number of defensive points scored during the year while the player was on the line',
      },
      {
        name: 'secondsPlayed',
        details: 'integer',
        info: 'The number of seconds on the field by the player',
      },
      {
        name: 'oOpportunities',
        details: 'integer',
        info: 'The number of distinct times the player was on offense',
      },
      {
        name: 'oOpportunityScores',
        details: 'integer',
        info: 'The number of times the team scored while the player was on offense',
      },
      {
        name: 'dOpportunities',
        details: 'integer',
        info: 'The number of distinct times the player was on defense',
      },
      {
        name: 'dOpportunityStops',
        details: 'integer',
        info: 'The number of times the team stopped the opposing team while the player was on defense',
      },
    ],
  },
];

export default ({ id }) => {
  return (
    <div className="section" id={id}>
      <hr />
      <h2 className="section-header">Player Stats</h2>
      <div className="section-container">
        <div className="section-left">
          <div className="description">
            Returns the cumulative stats for an entire year.
            The stats retrieved is the sum of each of the stats retrieved from the Player Game Stats endpoint.
            If a player changes teams mid-season, this endpoint will include stats from both teams.
          </div>
          <hr />
          <SectionTemplate sections={SECTIONS} />
        </div>
        <div className="section-right">
          <div className="sticky">
            <div className="code-example">
              <div className="code-example-header">
                ENDPOINT
              </div>
              <div className="code-example-body">
                <A href={process.env.REACT_APP_EXPRESS_URL + 'playerStats?playerIDs=bjagt&years=2019'}>
                  <code>{process.env.REACT_APP_EXPRESS_URL + 'playerStats'}</code>
                </A>
              </div>
            </div>
            <div className="response-example">
              <div className="response-example-header">
                RESPONSE
              </div>
              <pre className="response-example-body">{
                `{
  "object": `}<span className="json-string">"list"</span>{`,
  "data": [
    {
      "player": {
        "playerID": `}<span className="json-string">"bjagt"</span>{`,
        "firstName": `}<span className="json-string">"Ben"</span>{`,
        "lastName": `}<span className="json-string">"Jagt"</span>{`
      },
      "year": `}<span className="json-number">2019</span>{`,
      "assists": `}<span className="json-number">64</span>{`,
      "goals": `}<span className="json-number">67</span>{`,
      "hockeyAssists": `}<span className="json-number">26</span>{`,
      "completions": `}<span className="json-number">287</span>{`,
      "throwAttempts": `}<span className="json-number">318</span>{`,
      "throwaways": `}<span className="json-number">27</span>{`,
      "stalls": `}<span className="json-number">0</span>{`,
      "callahansThrown": `}<span className="json-number">0</span>{`,
      "yardsReceived": `}<span className="json-number">827</span>{`,
      "yardsThrown": `}<span className="json-number">173</span>{`,
      "hucksAttempted": `}<span className="json-number">3</span>{`,
      "hucksCompleted": `}<span className="json-number">3</span>{`,
      "catches": `}<span className="json-number">376</span>{`,
      "drops": `}<span className="json-number">7</span>{`,
      "blocks": `}<span className="json-number">14</span>{`,
      "callahans": `}<span className="json-number">0</span>{`,
      "pulls": `}<span className="json-number">16</span>{`,
      "recordedPulls": `}<span className="json-number">0</span>{`,
      "obPulls": `}<span className="json-number">16</span>{`,
      "recordedPullsHangtime": `}<span className="json-number">100658</span>{`,
      "oPointsPlayed": `}<span className="json-number">292</span>{`,
      "oPointsScored": `}<span className="json-number">225</span>{`,
      "dPointsPlayed": `}<span className="json-number">90</span>{`,
      "dPointsScored": `}<span className="json-number">37</span>{`,
      "secondsPlayed": `}<span className="json-number">23551</span>{`,
      "oOpportunities": `}<span className="json-number">453</span>{`,
      "oOpportunityScores": `}<span className="json-number">262</span>{`,
      "dOpportunities": `}<span className="json-number">254</span>{`,
      "dOpportunityStops": `}<span className="json-number">182</span>{`
    },
    {...},
    {...}
  ]
}`}
              </pre>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};
