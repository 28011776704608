import React from 'react';
import A from '../components/externalLink';
import SectionTemplate from '../components/sectionTemplate';

const SECTIONS = [
  {
    header: 'Parameters',
    parameters: [
      {
        name: 'date',
        details: 'required if no gameIDs',
        info: 'An inclusive date range. The full format is YYYY-MM-DD:YYYY-MM-DD, but you can exclude months or days and it will infer them. Examples:',
        examples: [
          () => <><A href={process.env.REACT_APP_EXPRESS_URL + 'games?date=2021-06:2021-07'}>2021-06:2021-07</A> ={'>'} All games during June or July</>,
          () => <><A href={process.env.REACT_APP_EXPRESS_URL + 'games?date=2021'}>2021</A> ={'>'} All games during 2021</>,
          () => <><A href={process.env.REACT_APP_EXPRESS_URL + 'games?date=2021-07-10:'}>2021-07-10:</A> ={'>'} All games on or after July 10th, 2021</>,
          () => <><A href={process.env.REACT_APP_EXPRESS_URL + 'games?date=:2012-05'}>:2012-05</A> ={'>'} All games during or before May, 2012</>,
        ],
      },
      {
        name: 'gameIDs',
        details: 'required if no date',
        info: 'A comma-delimited list of game IDs.',
      },
      {
        name: 'statuses',
        details: 'optional',
        info: 'A comma-delimited list of statuses. Options are Upcoming, Live, and Final.',
      },
      {
        name: 'teamIDs',
        details: 'optional',
        info: 'A comma-delimited list of team IDs. The team can be either home or away.',
      },
      {
        name: 'weeks',
        details: 'optional',
        info: 'A comma-delimited list of weeks.',
      },
      {
        name: 'updatedSince',
        details: 'optional',
        info: 'A timestamp to retreive recently updated games. Ideal format is ISO 8601 in UTC.',
        examples: [
          () => <code>2020-03-16T14:59:15Z</code>,
          () => <code>2019-11-22T01:03:59.264Z</code>,
        ],
      },
    ],
  },
  {
    header: 'Returns',
    parameters: [
      {
        name: 'gameID',
        details: 'string',
        info: 'The game\'s ID',
      },
      {
        name: 'awayTeamID',
        details: 'string',
        info: 'The away team\'s ID',
      },
      {
        name: 'homeTeamID',
        details: 'string',
        info: 'The home team\'s ID',
      },
      {
        name: 'awayScore',
        details: 'integer',
        info: 'The away team\'s score',
      },
      {
        name: 'homeScore',
        details: 'integer',
        info: 'The home team\'s score',
      },
      {
        name: 'status',
        details: 'string',
        info: <>
          The game's status. The following statuses are possible:<br />
          Upcoming, Delayed, First Quarter, End of Q1, Second Quarter, End of Half, Third Quarter, End of Q3, Fourth Quarter, End of Q4, First Overtime, End of OT1, Second Overtime, Final, Postponed, and Abandoned
        </>,
      },
      {
        name: 'startTimestamp',
        details: 'ISO 8601 Date w/ offset',
        info: 'The games\'s start time with accurate time offset',
      },
      {
        name: 'startTimezone',
        details: 'string',
        info: 'The timezone where the game is played',
      },
      {
        name: 'streamingURL',
        details: 'string',
        info: 'The URL for streaming. Usually watchufatv but could be FS2.',
      },
      {
        name: 'updateTimestamp',
        details: 'ISO 8601 Date in UTC',
        info: 'When any game stat was last updated including player statistics',
      },
      {
        name: 'week',
        details: 'string',
        info: 'The game\'s week',
      },
    ],
  },
];

export default ({ id }) => {
  return (
    <div className="section" id={id}>
      <hr />
      <h2 className="section-header">Games</h2>
      <div className="section-container">
        <div className="section-left">
          <div className="description">
            Returns a list of games.
            This API contains a game's home team, away team, start time, scores, and status.
            The gameID is a unique identifier to call other API endpoints and link to the website (e.g. <A href="https://watchufa.com/league/game/2019-04-14-MAD-IND">watchufa.com/league/game/2019-04-14-MAD-IND</A>).
          </div>
          <hr />
          <SectionTemplate sections={SECTIONS} />
        </div>
        <div className="section-right">
          <div className="sticky">
            <div className="code-example">
              <div className="code-example-header">
                ENDPOINT
              </div>
              <div className="code-example-body">
                <A href={process.env.REACT_APP_EXPRESS_URL + 'games?date=2019-08-11'}>
                  <code>{process.env.REACT_APP_EXPRESS_URL + 'games'}</code>
                </A>
              </div>
            </div>
            <div className="response-example">
              <div className="response-example-header">
                RESPONSE
              </div>
              <pre className="response-example-body">{
                `{
  "object": `}<span className="json-string">"list"</span>{`,
  "data": [
    {
      "gameID": `}<span className="json-string">"2022-04-29-SLC-SD"</span>{`,
      "awayTeamID": `}<span className="json-string">"shred"</span>{`,
      "homeTeamID": `}<span className="json-string">"growlers"</span>{`,
      "awayScore": `}<span className="json-number">0</span>{`,
      "homeScore": `}<span className="json-number">0</span>{`,
      "status": `}<span className="json-string">"Upcoming"</span>{`,
      "startTimestamp": `}<span className="json-string">"2022-04-29T19:00:00-07:00"</span>{`,
      "startTimezone": `}<span className="json-string">"PDT"</span>{`,
      "streamingURL": `}<span className="json-string">"https://audltv.vhx.tv/videos/salt-lake-at-san-diego-04-29-2022"</span>{`,
      "updateTimestamp": `}<span className="json-string">"2022-03-06T22:00:19.314Z"</span>{`,
      "week": `}<span className="json-string">"week-1"</span>{`
    },
    {...},
    {...}
  ]
}`}
              </pre>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};
